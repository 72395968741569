import React from 'react';

function FooterNote() {
    return (
        <div className="footernotes">
            <p>Note: Rates and conditions are subject to change at any time.</p>
        </div>
    )
}

export default FooterNote
